














































































































































































































































































































































































































































































































.header {
  background: #ff8a00;
  padding: 24px 12px;

  &-row {
    align-items: center;
  }

  &-logo {
    margin-right: 30px;

    &-img {
      width: 85px;
      height: 85px;
      border-radius: 50%;
      border: 2px solid #ffffff;
    }
  }

  &-title {
    margin: 0;
    font-family: "Raleway Bold", serif;
    color: #ffffff;
    font-size: 28px;
    font-weight: 900;
    word-break: break-word;
  }
}

.body {
  padding: 0px 12px 30px;

  &-payment {
    &-methods {
      &-small-amount {
        margin-top: 24px;
        text-align: center;
        color: #2d2e2f;
        font-family: "Raleway Bold", serif;
        font-size: 16px;
      }
    }
  }

  &-total-amount {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 12px;
    font-family: "Raleway Bold", serif;
    font-feature-settings: "pnum" on, "lnum" on;
    font-size: 16px;
    color: #2d2e2f;
  }

  &-amount {
    &-collapse {
      width: 100%;
      max-width: 730px;
      margin: 50px auto 0 auto;

      &-title {
        margin-left: 20px;
      }

      &-label {
        font-family: "Raleway Regular", serif;
        font-size: 16px;
        font-feature-settings: "tnum" on, "lnum" on;
      }
    }

    &-text {
      font-family: "Raleway Bold", serif;
      font-feature-settings: "pnum" on, "lnum" on;
      font-size: 16px;
      color: #2d2e2f;
      text-align: center;
      margin-top: 12px;
    }
  }

  &-terms::v-deep {
    max-width: 350px;
    margin: 25px auto 0 auto;

    .el-checkbox {
      display: flex;
      align-items: flex-start;
      width: 100%;

      &__inner {
        width: 16px;
        height: 16px;
        background: #2d2e2f;
        border: none;
        border-radius: 2px;

        &:after {
          height: 8px;
          width: 4px;
          border: 2px solid #ffffff;
          border-left: 0;
          border-top: 0;
          left: 5px;
          top: 2px;
        }
      }

      &__label {
        font-family: "Raleway Medium", serif;
        font-size: 12px;
        line-height: 16px;
        color: #2d2e2f;

        span {
          white-space: break-spaces;
        }

        .terms-link {
          color: #b5b5b5;
          text-decoration: underline;
        }
      }
    }
  }

  &-logo {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    img {
      width: 100px;
      height: 60px;
    }

    a {
      width: max-content;
      margin: 0 auto;

      &.body-logo-text-link {
        margin-top: 10px;
      }
    }
  }

  &-certification-logo {
    margin-top: 10px;

    .el-col {
      @media (max-width: 768px) {
        text-align: center;
        &:nth-child(2n) {
          margin-top: 10px;
        }
      }
    }

    img {
      height: 80px;
    }
  }
}
